<template>
  <div class="app-container">
  <el-row :gutter="4" type="flex" justify="center">
    <el-col :span="10">
      <el-card class="box-card">
        <div slot="header" class="clearfix" justify="center" align="center">
          <strong><span>Crear plan de Paypal</span></strong>
        </div>
        <div class="text item">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <el-form label-position="top" class="size-form-item" label-width="120px" :model="form">
              <el-row justify="space-around" align="middle">
              </el-row>
              <el-row justify="space-around">
                <el-col :sm="24" :md="24" :lg="24" align="left">
                  <el-form-item label="Clave de producto Paypal">
                    <ValidationProvider name="Clave de producto Stripe" rules="required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.product" disabled></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Nombre de producto">
                    <ValidationProvider name="Nombre de producto" rules="names|required" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.name" type="text"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Monto">
                    <ValidationProvider name="Monto" rules="required|integers" v-slot="{ errors }">
                      <el-input size="mini" v-model="form.amount" type="number" min="1"></el-input>
                      <span class="label-form-validate-wrong">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Divisa">
                    <ValidationProvider name="Divisa" rules="required" v-slot="{ errors }">
                      <el-select size="mini" v-model="form.currency" disabled>
                        <el-option
                          v-for="item in currency"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span class="label-form-validate-wrong" style="margin-left: 1rem;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                  <el-form-item label="Intervalo">
                    <ValidationProvider name="Intervalo" rules="required" v-slot="{ errors }">
                      <el-select size="mini" v-model="form.interval" disabled>
                        <el-option
                          v-for="item in interval"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                      <span class="label-form-validate-wrong" style="margin-left: 1rem;">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" justify="center">
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCreate"
                      type="primary"
                      icon="el-icon-upload2"
                      :disabled="invalid">
                      Crear
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="8" :md="4" align="middle">
                  <el-form-item>
                    <el-button
                      @click.prevent="handlerCancel"
                      type="danger">
                      Cancelar
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </div>
      </el-card>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import { storePaypalPlan } from '@/api/paypal.js'

export default {
  name: 'createPaypalPlan',
  data () {
    return {
      form: {
        product: '',
        name: '',
        amount: '',
        currency: 'MXN',
        interval: 'MONTH'
      },
      currency: [
        {
          label: 'Pesos mexicanos',
          value: 'MXN'
        },
        {
          label: 'Dolares americanos',
          value: 'USD'
        },
        {
          label: 'Euros',
          value: 'EUR'
        }
      ],
      interval: [
        {
          label: 'Mensual',
          value: 'MONTH'
        }
      ]
    }
  },
  mounted () {
    this.getProductId()
  },
  methods: {
    getProductId () {
      console.log('loading...')
      this.form.product = this.$route.query.product
    },
    handlerCancel () {
      this.$router.push('/projects')
    },
    async handlerCreate (info) {
      console.log(this.form)
      if (this.form.amount !== '' && this.form.currency !== null && this.form.product && this.form.interval) {
        try {
          const formResult = JSON.parse(JSON.stringify(this.form))
          const response = await storePaypalPlan(formResult)
          if (response.success) {
            console.log(response)
            this.$message({
              showClose: true,
              message: '¡El plan se creo correctamente!',
              type: 'success'
            })
            const param = this.$route.query.product
            this.$router.push(`/paypal/plan?product=${param}`)
          } else {
            console.log(response)
          }
        } catch (err) {
          console.log(err)
          /* err.response.data.messages.forEach(message => {
            this.$message.error(`${message}`)
          }) */
        }
      } else {
        this.$message.error('Asegurate que no falte ningun archivo o dato')
      }
    }
  }
}
</script>

<style scoped>
.label-form-validate-wrong {
  color: #F56C6C;
}

.size-form-item > .el-form-item {
  margin: 0;
}
.size-form-item > .el-form--label-top .el-form-item__label {
  padding: 0;
  margin-bottom: 0;
}
.my-autocomplete {
  width: 500px;
}

.project-slide {
  text-align: center;
}

.project-slide img {
  max-width: 100%;
  max-height: 300px; /* Ajusta la altura según las necesidades */
}
</style>
